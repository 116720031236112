<template>
  <!-- 仓库调出单明细 -->
  <div class="check-detail">
    <StoreDetail :billType="billType" :shopType="shopType" ref="storeDetail" :tableMiddleSpecialCount="1">
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <!-- <div class="searchItem">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              v-model="scoped.form.billDate"
              type="date"
              placeholder="选择日期"
              :disabled="scoped.disabled"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div> -->
        <div class="searchItem">
          <el-form-item label="调出仓库" prop="outStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame v-show="shopType==1" ref="selectOutStore" v-model="scoped.form.outStoreName"
              :tableData="scoped.storeData" :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'outStoreId', 'form')"
              @clickAdd="handleStore('add', 'outStoreId')" @click="handleStore('update', 'outStoreId')"
              :normalizer="scoped.normalizerStore"></SelectFrame>
            <SelectRemote v-show="shopType==2" v-model="scoped.form.outStoreId" :option="
                  $select({
                    key: 'listStore',
                    option: {
                      option: {
                        disabled: scoped.disabled,
                      },
                    },
                  }).option
                " />
          </el-form-item>
        </div>
        <div class="searchItem">
          <el-form-item label="调入仓库" prop="inStoreId">
            <!-- 仓库自定义下拉框 -->
            <SelectFrame v-show="shopType==1" ref="selectInStore" v-model="scoped.form.inStoreName"
              :tableData="scoped.storeData" :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'inStoreId', 'form')"
              @clickAdd="handleStore('add', 'inStoreId')" @click="handleStore('update', 'inStoreId')"
              :normalizer="scoped.normalizerStore"></SelectFrame>
            <SelectLocal v-show="shopType==2" v-model="scoped.form.inStoreId" :option="
                  $select({
                    key: 'listFranchiseStore',
                    option: {
                      option: {
                        disabled: scoped.disabled,
                      },
                    },
                  }).option
                " />
          </el-form-item>
        </div>
      </template>
      <template #tableMiddleSpecial>
        <el-table-column label="订货数量" align="center" prop="importUnitQty" show-overflow-tooltip width="120">
        </el-table-column>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import StoreDetail from '@/views/components/bill/storeDetail.vue'
import selectStore from '@/views/components/selectStore' //选择仓库组件
import storeAdd from '@/views/components/selectStore/storeAdd.vue' //新增仓库组件
import { number } from 'echarts/lib/export'
export default {
  name: 'storeMoveOutDetail',
  components: { StoreDetail, selectStore, storeAdd, SelectRemote, SelectLocal },
  data() {
    return {
      shopType: 1, //仓库类型
      billType: '',
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      //仓库类型
      storeType: undefined,
      //搜索框ref
      ref: undefined
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      vm.shopType = vm.$route.query.shopType ? Number(vm.$route.query.shopType) : 1;
      vm.billType = '130105'
      vm.loading = false;
    });
  },
  methods: {
    //新增仓库/选择仓库
    handleStore(type, name) {
      if (type === 'add') {
        this.OpenStoreAdd = true
      } else {
        this.OpenStore = true
      }
      if (name === 'outStoreId') {
        this.storeType = name
        this.ref = 'selectOutStore'
      } else {
        this.storeType = name
        this.ref = 'selectInStore'
      }
    },
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(
        event,
        'form',
        this.storeType,
        this.ref,
        true
      )
    }
  }
}
</script>
