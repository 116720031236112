<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      v-if="!showList"
      class="detail"
      v-model="optionsDetail"
      @handleEvent="handleEventDetail"
    />
    <StockQueryDetail
      :openStockQuery.sync="openStockQuery"
      :goodsDetailData="goodsDetailData"
    />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  storeInventoryGoodsList,
  storeInventoryGoodsDetail
} from '@/api/storeReport' //接口api
import StockQueryDetail from './components/stockQueryDetail.vue' //商品库存详情明细对话框组件
export default {
  name: 'StockQuery',
  components: { TablePage, StockQueryDetail },
  data () {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '商品库存查询',
        tableTitle: '商品库存查询',
        exportOption: {
          show: true,
          exportApi: 'exportListStoreBookReport',
          exportName: '商品库存查询',
          timeout: 60*5*1000
        },
        search: [
          this.$select({
            key: 'listCategory',
            option: { filter: 'categoryIds', option: { multiple: true } }
          }),
          this.$select({
            key: 'listGoods',
            option: { filter: 'goodsIds', option: { multiple: true } }
          }),
          this.$select({
            key: 'listStore',
            option: { filter: 'storeIds', option: { multiple: true } }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        getListApi: storeInventoryGoodsList,
        columns: [
          { label: '仓库', prop: 'storeName', minWidth: 120, fixed: true },
          {
            label: '商品编码',
            prop: 'goodsNo',
            minWidth: 120,
            fixed: true,
            link: true,
            click: 'oepnDetail'
          },
          { label: '商品名称', prop: 'goodsName', minWidth: 100 },
          { label: '条码', prop: 'barcode', minWidth: 120 },
          { label: '商品类别', prop: 'categoryName', minWidth: 100 },
          { label: '批号', prop: 'batchNo', minWidth: 80 },
          { label: '规格', prop: 'goodsSpec', minWidth: 80 },
          { label: '基础单位', prop: 'unitName', minWidth: 80 },
          { label: '标准进价', prop: 'purPrice', minWidth: 120 },
          { label: '标准售价', prop: 'salePrice', minWidth: 100 },
          { label: '期初数', prop: 'firstQty', minWidth: 80 },
          { label: '期初金额', prop: 'firstMoney', minWidth: 100 },
          { label: '入库数', prop: 'inQty', minWidth: 80 },
          { label: '入库金额', prop: 'inMoney', minWidth: 100 },
          { label: '出库数', prop: 'outQty', minWidth: 80 },
          { label: '出库金额', prop: 'outMoney', minWidth: 100 },
          { label: '期末数', prop: 'finalQty', minWidth: 80 },
          { label: '期末金额', prop: 'finalMoney', minWidth: 100 }
        ]
      },
      optionsDetail: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '商品库存明细',
        tableTitle: '商品库存明细',
        rowKey: 'billId',
        // list: [],
        defaultBody: {
          goodsId: '',
          storeId: '',
          batchNo: '',
        },
        getListApi: storeInventoryGoodsDetail,
        getDataKey: (e)=> {
          this.optionsDetail.search = this.optionsDetail.search.map(item => ({
            ...item,
            model: e.data.mainReport[item.filter]
          }))
          return {list: e.data.tableDataInfo.rows, total: e.data.tableDataInfo.total}
        },
        search: [
          {
            type: 'input',
            label: '仓库',
            tip: ' ',
            model: '',
            filter: 'storeName',
            disabled: true
          },
          {
            type: 'input',
            label: '商品编码',
            tip: ' ',
            model: '',
            filter: 'goodsNo',
            disabled: true
          },
          {
            type: 'input',
            label: '商品名称',
            tip: ' ',
            model: '',
            filter: 'goodsName',
            disabled: true
          },
          {
            type: 'input',
            label: '商品条码',
            tip: ' ',
            model: '',
            filter: 'barcode',
            disabled: true
          },
          {
            type: 'input',
            label: '商品批号',
            tip: ' ',
            model: '',
            filter: 'batchNo',
            disabled: true
          },
          {
            type: 'input',
            label: '规格',
            tip: ' ',
            model: '',
            filter: 'goodsSpec',
            disabled: true
          },
          {
            type: 'input',
            label: '基础单位',
            tip: ' ',
            model: '',
            filter: 'unitName',
            disabled: true
          },
          {
            type: 'input',
            label: '成本价',
            tip: ' ',
            model: '',
            filter: 'costPrice',
            disabled: true
          },
          {
            type: 'input',
            label: '标准进价',
            tip: ' ',
            model: '',
            filter: 'purPrice',
            disabled: true
          },
          {
            type: 'input',
            label: '标准售价',
            tip: ' ',
            model: '',
            filter: 'salePrice',
            disabled: true
          },
          {
            type: 'input',
            label: '期初数',
            tip: ' ',
            model: '',
            filter: 'firstQty',
            disabled: true
          },
          {
            type: 'input',
            label: '期初金额',
            tip: ' ',
            model: '',
            filter: 'firstMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '入库数',
            tip: ' ',
            model: '',
            filter: 'inQty',
            disabled: true
          },
          {
            type: 'input',
            label: '入库金额',
            tip: ' ',
            model: '',
            filter: 'inMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '出库数',
            tip: ' ',
            model: '',
            filter: 'outQty',
            disabled: true
          },
          {
            type: 'input',
            label: '出库金额',
            tip: ' ',
            model: '',
            filter: 'outMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '期末数',
            tip: ' ',
            model: '',
            filter: 'finalQty',
            disabled: true
          },
          {
            type: 'input',
            label: '期末金额',
            tip: ' ',
            model: '',
            filter: 'finalMoney',
            disabled: true
          },
          { type: 'button', tip: '关闭', btnType: 'primary', click: 'close' }
        ],
        summary: [
          'firstQty',
          'firstMoney',
          'inQty',
          'inMoney',
          'outQty',
          'outMoney',
          'finalQty',
          'finalMoney'
        ],
        columns: [
          { label: '记账时间', prop: 'auditTime', fixed: true, minWidth: 120 },
          {
            label: '单据编号',
            prop: 'billNo',
            minWidth: 120,
            fixed: true,
            link: true,
            click: 'getBillData'
          },
          { label: '单据日期', prop: 'billDate', minWidth: 100 },
          { label: '单据类型', prop: 'billTypeName', minWidth: 120 },
          { label: '商品批号', prop: 'batchNo', minWidth: 100 },
          // { label: '标准进价', prop: 'purPrice', minWidth: 120 },
          // { label: '标准售价', prop: 'salePrice', minWidth: 100 },
          { label: '期初数', prop: 'firstQty', minWidth: 80 },
          { label: '期初金额', prop: 'firstMoney', minWidth: 80 },
          { label: '入库数', prop: 'inQty', minWidth: 80 },
          { label: '入库金额', prop: 'inMoney', minWidth: 100 },
          { label: '出库数', prop: 'outQty', minWidth: 80 },
          { label: '出库金额', prop: 'outMoney', minWidth: 100 },
          { label: '期末数', prop: 'finalQty', minWidth: 80 },
          { label: '期末金额', prop: 'finalMoney', minWidth: 100 },
          { label: '备注', prop: 'remark' }
        ]
      }
    }
  },
  methods: {
    async handleEventDetail (type, row) {
      switch (type) {
        case 'close': // 关闭二级弹窗
          this.showList = true
          this.optionsDetail = {
              listNo: true,
              loading: true,
              pagination: {
                total: 0,
                page: 1,
                size: 15
              },
              body: {},
              title: '商品库存明细',
              tableTitle: '商品库存明细',
              rowKey: 'billId',
              defaultBody: {
              },
              getListApi: storeInventoryGoodsDetail,
              getDataKey: (e)=> {
                this.optionsDetail.search = this.optionsDetail.search.map(item => ({
                  ...item,
                  model: e.data.mainReport[item.filter]
                }))
                return {list: e.data.tableDataInfo.rows, total: e.data.tableDataInfo.total}
              },
              search: [
                {
                  type: 'input',
                  label: '仓库',
                  tip: ' ',
                  model: '',
                  filter: 'storeName',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '商品编码',
                  tip: ' ',
                  model: '',
                  filter: 'goodsNo',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '商品名称',
                  tip: ' ',
                  model: '',
                  filter: 'goodsName',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '商品条码',
                  tip: ' ',
                  model: '',
                  filter: 'barcode',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '商品批号',
                  tip: ' ',
                  model: '',
                  filter: 'batchNo',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '规格',
                  tip: ' ',
                  model: '',
                  filter: 'goodsSpec',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '基础单位',
                  tip: ' ',
                  model: '',
                  filter: 'unitName',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '成本价',
                  tip: ' ',
                  model: '',
                  filter: 'costPrice',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '标准进价',
                  tip: ' ',
                  model: '',
                  filter: 'purPrice',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '标准售价',
                  tip: ' ',
                  model: '',
                  filter: 'salePrice',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '期初数',
                  tip: ' ',
                  model: '',
                  filter: 'firstQty',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '期初金额',
                  tip: ' ',
                  model: '',
                  filter: 'firstMoney',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '入库数',
                  tip: ' ',
                  model: '',
                  filter: 'inQty',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '入库金额',
                  tip: ' ',
                  model: '',
                  filter: 'inMoney',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '出库数',
                  tip: ' ',
                  model: '',
                  filter: 'outQty',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '出库金额',
                  tip: ' ',
                  model: '',
                  filter: 'outMoney',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '期末数',
                  tip: ' ',
                  model: '',
                  filter: 'finalQty',
                  disabled: true
                },
                {
                  type: 'input',
                  label: '期末金额',
                  tip: ' ',
                  model: '',
                  filter: 'finalMoney',
                  disabled: true
                },
                { type: 'button', tip: '关闭', btnType: 'primary', click: 'close' }
              ],
              summary: [
                'firstQty',
                'firstMoney',
                'inQty',
                'inMoney',
                'outQty',
                'outMoney',
                'finalQty',
                'finalMoney'
              ],
              columns: [
                { label: '记账时间', prop: 'auditTime', fixed: true, minWidth: 120 },
                {
                  label: '单据编号',
                  prop: 'billNo',
                  minWidth: 120,
                  fixed: true,
                  link: true,
                  click: 'getBillData'
                },
                { label: '单据日期', prop: 'billDate', minWidth: 100 },
                { label: '单据类型', prop: 'billTypeName', minWidth: 120 },
                { label: '商品批号', prop: 'batchNo', minWidth: 100 },
                // { label: '标准进价', prop: 'purPrice', minWidth: 120 },
                // { label: '标准售价', prop: 'salePrice', minWidth: 100 },
                { label: '期初数', prop: 'firstQty', minWidth: 80 },
                { label: '期初金额', prop: 'firstMoney', minWidth: 80 },
                { label: '入库数', prop: 'inQty', minWidth: 80 },
                { label: '入库金额', prop: 'inMoney', minWidth: 100 },
                { label: '出库数', prop: 'outQty', minWidth: 80 },
                { label: '出库金额', prop: 'outMoney', minWidth: 100 },
                { label: '期末数', prop: 'finalQty', minWidth: 80 },
                { label: '期末金额', prop: 'finalMoney', minWidth: 100 },
                { label: '备注', prop: 'remark' }
              ]
          }
        break
      }
    },
    async handleEvent (type, row) {
      switch (type) {
        case 'close': // 关闭二级弹窗
          this.showList = true
          this.optionsDetail.list = []
          break
        case 'oepnDetail': //获取点击商品信息
          this.optionsDetail.defaultBody.goodsId = row.goodsId
          this.optionsDetail.defaultBody.storeId = row.storeId
          this.optionsDetail.defaultBody.batchNo = row.batchNo? row.batchNo : ''
          this.showList = false
          break
        case 'getBillData': // 明细点击
          // let billId = row.billId
          // let billType = row.billType
          // let routerName
          // if(billType === '102') routerName = 'PurchaseInstoreDetail'
          // if(billType === '103') routerName = 'PurchaseBackDetail'
          // if(billType === '105') routerName = 'SaleOutDetail'
          // if(billType === '106') routerName = 'SaleBackDetail'
          // if(billType === '107') routerName = 'storeCheckDetail'
          // if(billType === '108') routerName = 'storeAdjustDetail'
          // if(billType === '109') routerName = 'storeWasteDetail'
          // if(billType === '110') routerName = 'storeMoveDetail'
          // if(billType === '111') routerName = 'storeMoveOutDetail'
          // if(billType === '112') routerName = 'storeMoveInDetail'
          // if(billType === '113') routerName = 'storeCheckFullDetail'
          // if(billType === '114') routerName = 'storeCheckLoseDetail'
          // this.$router.push({
          //   name:routerName,
          //   query: {
          //     billId,
          //     type: 'Update'
          //   }
          // })
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-table {
    height: 500px !important;
    max-height: 500px !important;
    overflow: visible !important;
  }
}
</style>
